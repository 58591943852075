// extracted by mini-css-extract-plugin
export const main = "main-module--main--1FvNJ";
export const article = "main-module--article--2jFsv";
export const headerbg = "main-module--headerbg--16AU3";
export const headerbgimg = "main-module--headerbgimg--3SwND";
export const headerbox = "main-module--headerbox--SRAzG";
export const prehead = "main-module--prehead--VhQOt";
export const h0 = "main-module--h0--NTeF_";
export const desc = "main-module--desc--1EfeX";
export const descLine = "main-module--descLine--27u8F";
export const box = "main-module--box--2G4QO";
export const tbox = "main-module--tbox--2ZzNy";
export const txt = "main-module--txt--1T3jE";
export const htxt = "main-module--htxt--2apbL";
export const art1 = "main-module--art1--VlUQi";
export const art2 = "main-module--art2--14cYR";
export const art3 = "main-module--art3--1Kgh6";
export const art4 = "main-module--art4--DXfg-";
export const imghead = "main-module--imghead--1lnP1";