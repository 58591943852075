import React, { useEffect, useState } from 'react'
import * as s from "./main.module.css"

import { gsap } from "gsap/dist/gsap";

import { withpath } from '../utils/index.js'

import { Waypoint } from 'react-waypoint';

import Art1 from './art1/index'
import Art2 from './art2/index'
import Art3 from './art3/index'
import Art4 from './art4/index'

const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  let hdistance = -100
  let hdistancetxt = -60
  let imgscale = .65

  const [anim1active, setAnim1Active] = useState(false);
  const [anim2active, setAnim2Active] = useState(false);
  const [anim3active, setAnim3Active] = useState(false);

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
  });

	useEffect(() => {

    let headerimg = document.querySelectorAll(`#headerimg`);
    let prehead = document.querySelectorAll(`#prehead`);
    let head = document.querySelectorAll(`#head`);
    let intro = document.querySelectorAll(`#intro`);

    gsap.killTweensOf(headerimg,prehead,head,intro)
    gsap.set(headerimg, {opacity: 0, scale: 1.1})
    gsap.set([prehead,head,intro], {opacity: 0, translateY: 16})
    
    let htl = gsap.timeline({ paused: false });
      htl.to(headerimg, {opacity: 1, scale: 1, duration: 1.8, transformOrigin: '50% 50%', ease: `power2.out` })
      htl.to([prehead,head,intro], {opacity: 1, translateY: 0, duration: .8, stagger: .2,  ease: `power2.out` }, '<.5')

    let g1country1 = document.querySelectorAll(`#g1-country1`);
    let g1country2 = document.querySelectorAll(`#g1-country2`);
    let g1country3 = document.querySelectorAll(`#g1-country3`);
    let g1country4 = document.querySelectorAll(`#g1-country4`);
    let g1country5 = document.querySelectorAll(`#g1-country5`);
    let g1country6 = document.querySelectorAll(`#g1-country6`);
    let g1country7 = document.querySelectorAll(`#g1-country7`);
    let g1country8 = document.querySelectorAll(`#g1-country8`);
    let g1country9 = document.querySelectorAll(`#g1-country9`);
    let g1country10 = document.querySelectorAll(`#g1-country10`);
    let g1country11 = document.querySelectorAll(`#g1-country11`);
    let g1country12 = document.querySelectorAll(`#g1-country12`);
    let g1country13 = document.querySelectorAll(`#g1-country13`);
    let g1country14 = document.querySelectorAll(`#g1-country14`);
    let g1country15 = document.querySelectorAll(`#g1-country15`);
    let g1country16 = document.querySelectorAll(`#g1-country16`);
    let g1country17 = document.querySelectorAll(`#g1-country17`);
    let g1country18 = document.querySelectorAll(`#g1-country18`);
    let g1country19 = document.querySelectorAll(`#g1-country19`);
    let g1country20 = document.querySelectorAll(`#g1-country20`);
    let g1country21 = document.querySelectorAll(`#g1-country21`);
    let g1country22 = document.querySelectorAll(`#g1-country22`);
    let g1country23 = document.querySelectorAll(`#g1-country23`);
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1txt5 = document.querySelectorAll(`#g1-txt5`);
    let g1outline1 = document.querySelectorAll(`#g1-outline1`);

    gsap.killTweensOf([g1country1,g1country2,g1country3,g1country4,g1country5,g1country6,g1country7,g1country8,g1country9,g1country10,g1country11,g1country12,g1country13,g1country14,g1country15,g1country16,g1country17,g1country18,g1country19,g1country20,g1country21,g1country22,g1country23])
		gsap.set([g1country1,g1country2,g1country3,g1country4,g1country5,g1country6,g1country7,g1country8,g1country9,g1country10,g1country11,g1country12,g1country13,g1country14,g1country15,g1country16,g1country17,g1country18,g1country19,g1country20,g1country21,g1country22,g1country23,g1txt1,g1txt2,g1txt3,g1txt4,g1txt5], {opacity: 0})


    
    let g2arrow1 = document.querySelectorAll(`#g2-arrow1`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2img1 = document.querySelectorAll(`#g2-img1`);

    gsap.killTweensOf([g2arrow1,g2txt1,g2txt2,g2txt3,g2img1])
		gsap.set([g2arrow1,g2txt1,g2txt2,g2txt3], {opacity: 0})
		gsap.set(g2arrow1, {opacity: 0})
    gsap.set(g2img1, {opacity: 0, scale: imgscale})
    

    let g3arrow1 = document.querySelectorAll(`#g3-arrow1`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3img1 = document.querySelectorAll(`#g3-img1`);

    gsap.killTweensOf([g3arrow1,g3txt1,g3txt2,g3img1])
		gsap.set([g3arrow1,g3txt1,g3txt2], {opacity: 0})
		gsap.set(g3arrow1, {opacity: 0})
    gsap.set(g3img1, {opacity: 0, scale: imgscale})
    

    let g4seg1 = document.querySelectorAll(`#g4-seg1`);
    let g4seg2 = document.querySelectorAll(`#g4-seg2`);
    let g4seg3 = document.querySelectorAll(`#g4-seg3`);
    let g4seg4 = document.querySelectorAll(`#g4-seg4`);
    let g4seg5 = document.querySelectorAll(`#g4-seg5`);
    let g4seg6 = document.querySelectorAll(`#g4-seg6`);
    let g4seg7 = document.querySelectorAll(`#g4-seg7`);
    let g4txt1 = document.querySelectorAll(`#g4-txt1`);
    let g4txt2 = document.querySelectorAll(`#g4-txt2`);
    let g4txt3 = document.querySelectorAll(`#g4-txt3`);
    let g4txt4 = document.querySelectorAll(`#g4-txt4`);
    let g4txt5 = document.querySelectorAll(`#g4-txt5`);
    let g4txt6 = document.querySelectorAll(`#g4-txt6`);
    let g4txt7 = document.querySelectorAll(`#g4-txt7`);
    let g4line1 = document.querySelectorAll(`#g4-line1`);
    let g4line2 = document.querySelectorAll(`#g4-line2`);
    let g4line3 = document.querySelectorAll(`#g4-line3`);
    let g4line4 = document.querySelectorAll(`#g4-line4`);
    let g4line5 = document.querySelectorAll(`#g4-line5`);
    let g4line6 = document.querySelectorAll(`#g4-line6`);
    let g4line7 = document.querySelectorAll(`#g4-line7`);
    let g4globe1 = document.querySelectorAll(`#g4-globe1`);

    gsap.killTweensOf([g4seg1,g4seg2,g4seg3,g4seg4,g4seg5,g4seg6,g4seg7,g4txt1,g4txt2,g4txt3,g4txt4,g4txt5,g4txt6,g4txt7,g4line1,g4line2,g4line3,g4line4,g4line5,g4line6,g4line7,g4globe1])
		gsap.set([g4txt1,g4txt2,g4txt3,g4txt4,g4txt5,g4txt6,g4txt7,g4line1,g4line2,g4line3,g4line4,g4line5,g4line6,g4line7], {opacity: 0})
		gsap.set([g4seg1,g4seg2,g4seg3,g4seg4,g4seg5,g4seg6,g4seg7,g4globe1], {opacity: 0, scale: imgscale})

  },[])




	function enter1(){

    setAnim1Active(true);

    let g1country1 = document.querySelectorAll(`#g1-country1`);
    let g1country2 = document.querySelectorAll(`#g1-country2`);
    let g1country3 = document.querySelectorAll(`#g1-country3`);
    let g1country4 = document.querySelectorAll(`#g1-country4`);
    let g1country5 = document.querySelectorAll(`#g1-country5`);
    let g1country6 = document.querySelectorAll(`#g1-country6`);
    let g1country7 = document.querySelectorAll(`#g1-country7`);
    let g1country8 = document.querySelectorAll(`#g1-country8`);
    let g1country9 = document.querySelectorAll(`#g1-country9`);
    let g1country10 = document.querySelectorAll(`#g1-country10`);
    let g1country11 = document.querySelectorAll(`#g1-country11`);
    let g1country12 = document.querySelectorAll(`#g1-country12`);
    let g1country13 = document.querySelectorAll(`#g1-country13`);
    let g1country14 = document.querySelectorAll(`#g1-country14`);
    let g1country15 = document.querySelectorAll(`#g1-country15`);
    let g1country16 = document.querySelectorAll(`#g1-country16`);
    let g1country17 = document.querySelectorAll(`#g1-country17`);
    let g1country18 = document.querySelectorAll(`#g1-country18`);
    let g1country19 = document.querySelectorAll(`#g1-country19`);
    let g1country20 = document.querySelectorAll(`#g1-country20`);
    let g1country21 = document.querySelectorAll(`#g1-country21`);
    let g1country22 = document.querySelectorAll(`#g1-country22`);
    let g1country23 = document.querySelectorAll(`#g1-country23`);
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1txt5 = document.querySelectorAll(`#g1-txt5`);
    let g1outline1 = document.querySelectorAll(`#g1-outline1`);

    gsap.killTweensOf([g1country1,g1country2,g1country3,g1country4,g1country5,g1country6,g1country7,g1country8,g1country9,g1country10,g1country11,g1country12,g1country13,g1country14,g1country15,g1country16,g1country17,g1country18,g1country19,g1country20,g1country21,g1country22,g1country23])
    gsap.set([g1country1,g1country2,g1country3,g1country4,g1country5,g1country6,g1country7,g1country8,g1country9,g1country10,g1country11,g1country12,g1country13,g1country14,g1country15,g1country16,g1country17,g1country18,g1country19,g1country20,g1country21,g1country22,g1country23], {opacity: 0})
    gsap.set([g1txt1,g1txt2,g1txt3,g1txt4,g1txt5], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to([g1country1,g1country2,g1country3,g1country4,g1country5,g1country6,g1country7,g1country8,g1country9,g1country10,g1country11,g1country12,g1country13,g1country14,g1country15,g1country16,g1country17,g1country18,g1country19,g1country20,g1country21,g1country22,g1country23], {opacity: 1, duration: 1.4, stagger: .2, ease: `power2.out` })
			tl.to([g1txt1,g1txt2,g1txt3,g1txt4,g1txt5], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.35')
  
	}

	function leave1(){
    
    setAnim1Active(false);

  }


	function enter2(){

    setAnim2Active(true);

    let g2arrow1 = document.querySelectorAll(`#g2-arrow1`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2img1 = document.querySelectorAll(`#g2-img1`);

    gsap.killTweensOf([g2arrow1,g2txt1,g2txt2,g2txt3,g2img1])
    gsap.set(g2arrow1, {opacity: 0, translateX: hdistance})
    gsap.set(g2img1, {opacity: 0, scale: imgscale})
    gsap.set([g2txt1,g2txt2,g2txt3], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g2arrow1, {opacity: 1, translateX: 0, duration: 1.8, ease: `power2.out` })
      tl.to(g2img1, {opacity: 1, scale: 1, duration: 1, translateX: 0, translateY: 0, transformOrigin: 'bottom left', ease: `power2.out` }, '<.2')
			tl.to([g2txt1, g2txt2, g2txt3], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.5')
      
	}

	function leave2(){

    setAnim2Active(false);
    
	}


  function enter3(){

    setAnim3Active(true);

    let g3arrow1 = document.querySelectorAll(`#g3-arrow1`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3img1 = document.querySelectorAll(`#g3-img1`);

    gsap.killTweensOf([g3arrow1,g3txt1,g3txt2,g3img1])
		gsap.set([g3arrow1], {opacity: 0, translateX: hdistance})
		gsap.set(g3arrow1, {opacity: 0})
    gsap.set(g3img1, {opacity: 0, scale: imgscale})
    gsap.set([g3txt1,g3txt2], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g3arrow1, {opacity: 1, translateX: 0, duration: 1.8, ease: `power2.out` })
      tl.to(g3img1, {opacity: 1, scale: 1, duration: 1, translateX: 0, translateY: 0, transformOrigin: 'bottom left', ease: `power2.out` }, '<.2')
			tl.to([g3txt1, g3txt2], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.5')
		
  }

  function leave3(){

    setAnim3Active(false);
		
  }


  function enter4(){

    let g4seg1 = document.querySelectorAll(`#g4-seg1`);
    let g4seg2 = document.querySelectorAll(`#g4-seg2`);
    let g4seg3 = document.querySelectorAll(`#g4-seg3`);
    let g4seg4 = document.querySelectorAll(`#g4-seg4`);
    let g4seg5 = document.querySelectorAll(`#g4-seg5`);
    let g4seg6 = document.querySelectorAll(`#g4-seg6`);
    let g4seg7 = document.querySelectorAll(`#g4-seg7`);
    let g4txt1 = document.querySelectorAll(`#g4-txt1`);
    let g4txt2 = document.querySelectorAll(`#g4-txt2`);
    let g4txt3 = document.querySelectorAll(`#g4-txt3`);
    let g4txt4 = document.querySelectorAll(`#g4-txt4`);
    let g4txt5 = document.querySelectorAll(`#g4-txt5`);
    let g4txt6 = document.querySelectorAll(`#g4-txt6`);
    let g4txt7 = document.querySelectorAll(`#g4-txt7`);
    let g4line1 = document.querySelectorAll(`#g4-line1`);
    let g4line2 = document.querySelectorAll(`#g4-line2`);
    let g4line3 = document.querySelectorAll(`#g4-line3`);
    let g4line4 = document.querySelectorAll(`#g4-line4`);
    let g4line5 = document.querySelectorAll(`#g4-line5`);
    let g4line6 = document.querySelectorAll(`#g4-line6`);
    let g4line7 = document.querySelectorAll(`#g4-line7`);
    let g4globe1 = document.querySelectorAll(`#g4-globe1`);

    gsap.killTweensOf([g4seg1,g4seg2,g4seg3,g4seg4,g4seg5,g4seg6,g4seg7,g4txt1,g4txt2,g4txt3,g4txt4,g4txt5,g4txt6,g4txt7,g4line1,g4line2,g4line3,g4line4,g4line5,g4line6,g4line7,g4globe1])
		gsap.set([g4txt1,g4txt2,g4txt3,g4txt4,g4txt5,g4txt6,g4txt7,g4line1,g4line2,g4line3,g4line4,g4line5,g4line6,g4line7], {opacity: 0})
    gsap.set([g4seg1,g4seg2,g4seg3,g4seg4,g4seg5,g4seg6,g4seg7,g4globe1], {opacity: 0, scale: imgscale})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g4globe1, {opacity: 1, scale: 1, duration: .8, translateX: 0, translateY: 0, transformOrigin: '50% 50%', ease: `power2.out` })
      tl.to([g4seg1,g4seg2,g4seg3,g4seg4,g4seg5,g4seg6,g4seg7], {opacity: 1, scale: 1, duration: .8,translateX: 0, translateY: 0, transformOrigin: '50% 50%', stagger: .2, ease: `power2.out` }, '<.1')
      tl.to([g4line1, g4txt1], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.5')
			tl.to([g4line2, g4txt2], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.12')
      tl.to([g4line3, g4txt3], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.12')
      tl.to([g4line4, g4txt4], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.12')
      tl.to([g4line5, g4txt5], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.12')
      tl.to([g4line6, g4txt6], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.12')
			tl.to([g4line7, g4txt7], {opacity: 1, duration: .8, stagger: .15, ease: `power2.out` }, '<.12')
		
  }

  function leave4(){
		
  }
  

	return (
		<section className={s.article} >

      {/* Hero Block */}
			<div 
				className={s.headerbg} 
			>
        <div id="headerimg" className={s.headerbgimg} style={{backgroundImage: `url(${withpath(`/img/hero_bg.jpg`)})` }}/>
			</div>
      <div className={s.headerbox}>
        <div id="prehead" className={s.prehead} dangerouslySetInnerHTML={{__html: data.prehead }} />
        <div id="head" className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
        <div id="intro" className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
        <div className={s.descLine} />
      </div>


      {/* Intro */}
			<div className={s.box} data-id={0}>

				<div className={s.tbox} data-id={0}>
					<div className={s.tboxinner} data-id={0}>
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
					</div>
				</div>

			</div>

      {/* Why Asia Now */}
			<div className={s.box} data-id={1}>

				<div className={s.tbox} data-id={1}>
          <div className={s.tboxinner} data-id={1}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />

            <Waypoint 
                onEnter={(p) => {enter1()}} 
                onLeave={(p) => {leave1()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art1}>
                <Art1 isAnimated={anim1active} />
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={4} />

            <Waypoint 
                onEnter={(p) => {enter2()}} 
                onLeave={(p) => {leave2()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art2}>
                <Art2 isAnimated={anim2active} />
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={0} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={5} />
          </div>
        </div>

			</div>


      {/* Borderless banking */}
			<div className={s.box} data-id={3}>

        <div className={s.tbox} data-id={2}>
          <div className={s.tboxinner} data-id={2}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={6} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={7} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={8} />

            <Waypoint 
                onEnter={(p) => {enter3()}} 
                onLeave={(p) => {leave3()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art3}>
                <Art3 isAnimated={anim3active} />
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={9} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={10} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={11} />
          </div>
        </div>

			</div>


      {/* The future's digital */}
			<div className={s.box} data-id={4}>

				<div className={s.tbox} data-id={5}>
					<div className={s.tboxl} data-id={5}>
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={12} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={13} />

            <Waypoint 
                onEnter={(p) => {enter4()}} 
                onLeave={(p) => {leave4()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art4}>
                <h5 className={s.imghead}>Asia represents 50% of global internet users</h5>
                <Art4 />
                <p className={s.imgcaption}>Source: Miniwatts Marketing Group, as of Q1 2020</p>
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={14} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={15} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={16} />
					</div>
				</div>

      </div>


		</section>
)}

export default Main
