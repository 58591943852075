import React from 'react'
import Main from '../components/main';
import data from '../data/data.json';
import '../styles/index.css'

const IndexPage = () => {
	return (
		<div className={`hsbc`}>
			<Main data={data} />
		</div>
	)
}

export default IndexPage